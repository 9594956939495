import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export default function ImgMediaCard({ item }) {
  return (
    <div style={{ width: '100%', padding: '20px', height: '700px', }}>
        <Card sx={{ width: '100%' }} elevation={8}>
            <div 
            style={{ backgroundColor: item?.bg }}
            >
                {item?.img && <CardMedia
                sx={{
                    height: '180px',
                    width: '100%',
                    maxWidth: '450px',
                    margin: 'auto',
                }}
                component="img"
                alt={item?.title}
                image={item?.img}
                />}
                
            {item?.logo && <div>{item?.logo}</div>}
            
            </div>
            <CardContent
                sx={{
                    // height: '350px',
                }}
            >
                <Typography gutterBottom variant="h5" component="div">
                {item?.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                {item?.text}
                </Typography>
            </CardContent>
            <CardActions>
                {item?.buttons.map((b) => {
                return (
                    <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    href={b.link}
                    size="small"
                    >
                    {b.name}<ArrowOutwardIcon/>
                    </Button>
                );
                })}
            </CardActions>
        </Card>
    </div>
  );
}
