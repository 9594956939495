import { Box } from '@mui/material'
import React from 'react'
import {Cloud, renderSimpleIcon, 
  fetchSimpleIcons,
} from 'react-icon-cloud'
// import {siJavascript, aws} from "simple-icons/icons"

// const icons = [siJavascript, aws].map((icon) => {
//   return renderSimpleIcon({
//     icon,
//     size: 42,
//     aProps: {
//       onClick: (e) => e.preventDefault()
//     }
//   })
// })

// const StaticIconCloud = () => {
//   return <Cloud>
//     {icons}
//   </Cloud>
// }

const useIcons = (slugs) => {
  const [icons, setIcons] = React.useState()
  React.useEffect(() => {fetchSimpleIcons({slugs}).then(({simpleIcons}) => setIcons(Object.values(simpleIcons)))}, [slugs])

  if (icons) {
    return icons.map((icon) => renderSimpleIcon({
      icon,
      size: 40,
      aProps: {
        onClick: (e) => e.preventDefault()
      },
    }))
  }

  return "Loading..."
}



const DynamicIconCloud = ({slugs}) => {
  const icons = useIcons(slugs)

  return (
    <Box 
      sx={{
        '&:hover': {
          backgroundColor: '#555',
        },
      }}
    >
      <Cloud>
        {icons}
      </Cloud>
    </Box>
  )
}


export default DynamicIconCloud;