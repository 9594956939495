import {  Typography } from '@mui/material';
import React from 'react';

export default function About() {
  return (
    <div id="about" className="container my-5">
      <div className="row">
        {/* <div className="col-lg-6 col-sm-12">
                    <img src="" alt=""/>
                </div> */}
        <div className="col-lg-12 col-sm-12">
          <h2>About Me</h2>
          <Typography variant='body1'>
            Hi, I'm Nandha. I am learning about Electronics, Communication
            Technologies, Ethical Hacking, Data Science, Web & App Development
            and Blockchain. And I have also done some projects. Check out my
            Resume up there. If you are interested to do any project with me,
            you can always contact me :)
          </Typography>
        </div>
      </div>
    </div>
  );
}
