import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
export default function OutlinedCard({course}) {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <React.Fragment>
            <CardContent>
                <Typography variant="h5" component="div">
                    {course.title}
                </Typography>
                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                    {course.time}
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                    {course.institution}
                </Typography>
                <Typography variant="body2">
                    {course.description}
                </Typography>
                </CardContent>
                <CardActions>
                    <Button 
                        href={course.certificate}
                        target="_blank" 
                        rel="noopener noreferrer"
                        size="small"
                    >
                        View Certificate
                    <ArrowOutwardIcon/>
                    </Button>
                </CardActions>
            </React.Fragment>
        </Card>
    </Box>
  );
}