import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Links from './components/home/Links';
import Appbar from './components/home/Appbar';
import Home from './components/home/Home';
import Resume from './components/resume/Resume';
import Footer from './components/home/Footer';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

export default function App() {
  const [mode, ] = useState('dark');
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode, // 'light' or 'dark'
        },
      }),
    [mode]
  );
  return (
    <div className="App w-100">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Appbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/resume" exact element={<Resume />} />
            <Route path="/links" exact element={<Links />} />
            {/* <Route path="/gallery" exact element={<Gallery/>} />
                  <Route path="/blog/:id" exact element={<Blog />} />
                  <Route path="/terms" exact element={<Terms/>} />
                  <Route path="/privacy" exact element={<Privacy/>} /> */}
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}
